.layout-watch {
  .side-title.side-title--left {
    left: 1%;
  }

  .side-title.side-title--right {
    right: 1%;
  }

  @include touch {
    #rf-logo-wrapper {
      width: 10%;
    }

    .page-header {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }

    .clock {
      display: none;
    }

    .logo {
      margin: var(--size-16) 0;
    }
  }
}
.page-header .clock {
  margin-right: var(--size-24);

  .time {
    font-size: var(--size-32);
  }
}
