#full-banner {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 5;
  background-color: var(--colour-white);
  padding: var(--size-8);
  border-top: 1px solid var(--colour-grey-light);
  border-left: 1px solid var(--colour-grey-light);
  border-color: var(--colour-grey-light);

  h1 {
    font-size: var(--size-16);
    font-weight: 800;
    margin: 0;
    color: rgba(29, 32, 34, 0.5);

    &.countdown,
    span {
      font-family: var(--font-logo);
    }

    .cd-time {
      color: rgba(29, 32, 34, 1);
    }
  }

  &.hidden {
    display: none;
  }

  @include touch {
    width: 100vw;
    box-sizing: border-box;
    padding: var(--size-8) var(--size-32);
    left: 0;

    h1 {
      // font-size: var(--size-24);
    }

    .cd-time {
      display: block;
      margin: 0;
    }
  }

  .now-live {
    color: var(--colour-red);
  }

  &.live {
    width: 100vw;
    h1 {
      font-size: 2rem;
      text-align: center;
    }
  }

  @include darkMode {
    border-color: var(--colour-graphite);
  }
}
