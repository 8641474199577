#page-donate {
  .content p:last-of-type {
    margin-top: 24px;
  }

  .donate-button {
    display: flex;
    justify-content: center;

    img {
      filter: hue-rotate(-25deg) drop-shadow(0px 1px 0px var(--colour-highlight));
    }
  }

  .content h2 {
    margin-top: 0;
  }
}
