::-webkit-scrollbar {
  // width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  // background-image: url('../img/bg/scroll.jpg');
  // background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  // background: transparent;
  // background-color: var(--colour-graphite);
  // background-blend-mode: difference;
  // background-size: cover;
  // border-radius: 50px;
}
