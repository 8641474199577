@mixin center-content-flex() {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin padding-vertical($padding) {
  padding-top: $padding;
  padding-bottom: $padding;
}

@mixin padding-horizontal($padding) {
  padding-left: $padding;
  padding-right: $padding;
}

@mixin margin-vertical($margin) {
  margin-top: $margin;
  margin-bottom: $margin;
}

@mixin margin-horizontal($margin) {
  margin-left: $margin;
  margin-right: $margin;
}

@mixin dir-col() {
  display: flex;
  flex-direction: column;
}

@mixin dir-row() {
  display: flex;
  flex-direction: row;
}

.margin-0 {
  margin: 0px !important;
}

.margin-4-bottom {
  margin-bottom: var(--size-base) !important;
}

.margin-8-bottom {
  margin-bottom: var(--size-8) !important;
}

.margin-16-bottom {
  margin-bottom: var(--size-16) !important;
}

.margin-16-bottom-m {
  @include touch {
    margin-bottom: var(--size-16) !important;
  }
}

.margin-24-bottom {
  margin-bottom: var(--size-24) !important;
}

.margin-32-bottom {
  margin-bottom: var(--size-32) !important;
}

.margin-64-bottom {
  margin-bottom: var(--size-64) !important;
}

.margin-128-bottom {
  margin-bottom: var(--size-128) !important;
}

.margin-16-left {
  margin-left: var(--size-16) !important;
}

.margin-16-left-m {
  @include touch {
    margin-left: var(--size-16) !important;
  }
}

.margin-16-left-d {
  @include desktop {
    margin-left: var(--size-16) !important;
  }
}

.margin-8-right-d {
  @include desktop {
    margin-right: var(--size-8) !important;
  }
}

.hide {
  display: none;
}

.hide-m {
  @include touch {
    display: none !important;
  }
}
