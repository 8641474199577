p,
a,
span,
h1,
h2,
h3,
h4,
h5,
h6,
li,
strong,
em,
label,
button,
small,
.separator {
  font-family: var(--font-body);
  color: var(--colour-text, var(--colour-graphite));
  margin-bottom: var(--size-16);
}

strong,
.bold {
  font-family: var(--font-body);
  font-weight: 700;
}

em,
.italic {
  font-family: var(--font-body);
  font-style: italic;
}

small {
  font-size: smaller;
  line-height: 1.5;
}

h1 {
  font-size: 84px;
}

h1,
h2,
h3,
h4 {
  font-family: var(--font-title);
  // font-style: italic;
}

h1,
.h1 {
  font-size: var(--size-64);
}

h2,
.h2 {
  font-size: var(--size-32);
}

h3,
.h3 {
  font-size: var(--size-24);
}

p,
.text-p {
  font-size: var(--size-14);
  font-family: var(--font-body);
  line-height: var(--size-32);
}

a {
  // color: var(--colour-graphite);
  font-weight: bold;

  &:hover,
  &:visited {
    font-style: italic;
  }
}

.separator {
  max-width: var(--size-512) + var(--size-128);
  display: flex;
  justify-content: center;
  @include margin-vertical(var(--size-16));

  .dot {
    height: var(--size-8);
    width: var(--size-8);
    border-radius: 100%;
    background-color: var(--colour-graphite);
    // mix-blend-mode: exclusion;

    &.background-blue {
      background-color: var(--colour-highlight-darker);
    }
    &.background-white {
      background-color: var(--colour-white);
    }
  }
}

a {
  color: var(--colour-turquoise);

  &:visited {
    color: var(--colour-highlight-darker);
  }

  &.with-url-arrow {
    text-decoration: none;
    &::before {
      content: '→ ';
    }
  }
}

.sup {
  font-size: small;
  vertical-align: super;
}

::selection {
  background-color: var(--colour-highlight);
  color: var(--colour-graphite);
}
