.archive-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-auto-rows: minmax(calc(920px / 3), max-content);
  column-gap: var(--size-16);

  @include touch {
    grid-template-columns: 1fr 1fr;
  }

  @include mobile {
    grid-template-columns: 1fr;
  }

  .event a {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    text-decoration: none;
    margin: 0;
  }

  .event {
    position: relative;
    padding: 0;

    .bg-img {
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
    }

    .inner {
      z-index: 1;
      padding: var(--size-8) var(--size-12);
      background-color: var(--colour-white);
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      box-sizing: border-box;

      p,
      .participant-name {
        padding: var(--size-8);
      }
    }

    .format-label {
      position: absolute;
      bottom: 0;
      right: 0;
      border-top: 1px solid var(--colour-graphite);
      border-left: 1px solid var(--colour-graphite);
      line-height: 0.8;
      padding: 2px 0 0 2px;
      background-color: var(--colour-white);
      z-index: 6;
      font-weight: normal;
    }

    .participants {
      font-weight: normal;

      span {
        margin: 0;

        &:last-of-type {
          margin-bottom: var(--size-24);
        }
      }
    }

    &:hover .bg-img,
    a:focus .bg-img {
      z-index: 3;
      // mix-blend-mode: lighten;
      opacity: 0.9;

      .inner {
        background-color: transparent;

        font-style: normal;

        p,
        .participant-name {
          background-color: var(--colour-white);
        }
      }
    }

    .text-lg {
      font-size: var(--size-24);
    }
  }
}
