.post-list {
  margin: 0;
  padding: 0;
  margin-bottom: var(--size-128);

  h2 {
    margin-bottom: var(--size-32);
  }

  .post-item {
    margin-bottom: var(--size-64);

    .post-header {
      margin-bottom: var(--size-16);
    }
    .post-content {
      p {
        display: inline;
      }
    }
  }
}

.news-post {
  margin-bottom: var(--size-64);

  h3 {
    margin-top: var(--size-32);
  }

  img {
    max-width: 100%;
  }

  button {
    padding: var(--size-16) var(--size-32);
    border-radius: 0;
    background: transparent;
    border: 1px solid var(--colour-graphite);
    width: 100%;
    cursor: pointer;

    &:hover {
      background: var(--colour-graphite);
      border: 1px solid var(--colour-graphite);
      color: var(--colour-bg);
    }
  }
}

.post-item {
  list-style: none;
  padding: 0;
  margin-bottom: var(--size-16);

  .post-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--size-32);
    h2,
    h3,
    label {
      display: inline-flex;
      align-items: center;
      @include textbox;
      margin: 0;
    }

    a {
      color: var(--colour-graphite);
      text-decoration: none;

      &:hover,
      &:visited {
        font-style: normal;
      }
    }

    @include touch {
      flex-wrap: wrap;

      h2 {
        margin-bottom: 8px;
      }
    }
  }

  .post-content {
    @include textbox;
    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    span,
    a,
    li {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  ul {
    margin-left: var(--size-16);
    margin-bottom: var(--size-32);
  }

  h3:not(:first-of-type) {
    margin-top: var(--size-32);
  }

  .tag {
    @include textbox;
    display: inline-block;
  }
}

.post-content {
  a.button {
    -webkit-appearance: none;
    border: none;
    box-shadow: none;
    font-size: var(--size-16);
    cursor: pointer;
  }
}
