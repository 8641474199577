.back-button {
  position: fixed;
  display: block;
  top: var(--size-32);
  background-color: white;
  padding: var(--size-24) var(--size-16);
  left: 5%;
  text-decoration: none;
  z-index: 1;
  border: 1px solid var(--colour-grey-light);
  border-radius: 1px;

  span {
    margin-bottom: var(--size-8);
    font-weight: normal;
  }

  @include touch {
    margin: 0;
    padding: var(--size-16) var(--size-16);
    left: var(--size-8);
  }

  @include darkMode {
    border-color: var(--colour-graphite);
  }
}
