button.btn-ghost {
  padding: var(--size-16) var(--size-32);
  border-radius: 0;
  background: transparent;
  border: 1px solid var(--colour-graphite);
  width: 100%;
  cursor: pointer;
  margin: 0;

  &:hover {
    background: var(--colour-graphite);
    border: 1px solid var(--colour-graphite);
    color: var(--colour-bg);
  }
}
