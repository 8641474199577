header.page-header {
  .nav-link,
  .lang,
  .sub-nav-link {
    color: var(--colour-text);
    background-color: white;
    transition: box-shadow 0.8s cubic-bezier(0.075, 0.82, 0.165, 1);
    z-index: 1;
    text-decoration: underline;

    &[href^='https']:after {
      content: '↗';
      margin-left: 2px;
    }
  }

  .nav-link,
  .lang {
    padding: 4px 8px;
  }

  .sub-nav-link {
    padding: 8px 10px;
  }

  .lang:hover {
    box-shadow: 5px 5px 0 rgba(var(--colour-white), 0.5), 10px 10px 0 rgba(var(--colour-white), 0.4),
      15px 15px 0 rgba(var(--colour-white), 0.3);
    transition: box-shadow 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
  }

  .nav-group {
    cursor: default;
    position: relative;
    z-index: 1;

    .sub-nav-group {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: 100%;
      padding: 18px 0;
      right: 0;

      &:hover,
      &:focus,
      &:active {
        opacity: 1;
        pointer-events: all;
      }
    }

    &:hover,
    &:focus,
    &:active {
      .sub-nav-group {
        opacity: 1;
        pointer-events: all;
      }
    }
    a {
      cursor: default;
    }
    a[href^='https'],
    a[href^='/'] {
      cursor: pointer;
      width: max-content;
    }
  }

  nav {
    position: absolute;
    top: var(--size-32);
    right: var(--size-32);
    display: flex;

    @include touch {
      position: relative;
      top: 0;
      left: 0;
      right: initial;
      margin-top: var(--size-16);
      justify-content: flex-end;
    }

    .nofocus:focus {
      outline: none;
    }
  }

  .lang,
  .nav-link,
  .sub-nav-link {
    margin: 0 4px 8px 0;

    @include touch {
      font-size: 0.8em;
    }
  }

  h1.logo {
    font-family: var(--font-logo);
    margin: var(--size-128) 0 var(--size-64) 0;
    color: var(--colour-text, var(--colour-graphite));
    display: inline-block;
    filter: drop-shadow(0px 0px 8px var(--colour-grey-light));

    @include touch {
      margin: var(--size-64) 0;
    }

    .subtitle {
      font-family: var(--font-logo);
      color: var(--colour-text, var(--colour-graphite));
      font-size: var(--size-32);
    }
  }

  &.header-collapsed {
    h1.logo {
      margin: 0;
      font-size: var(--size-32);
      margin: var(--size-32) 0;
    }

    @include touch {
      nav.page-content {
        flex-direction: column;
        margin-right: var(--size-16);
      }

      #rf-logo-wrapper {
        margin-left: var(--size-16);
      }
    }
  }
}

#rf-logo-wrapper {
  transition: all 0.8s ease-in-out;
  a {
    font-weight: normal;

    &:hover,
    &:visited {
      font-style: normal;
    }

    @include darkMode {
      h1,
      .subtitle {
        color: var(--colour-white);
        filter: drop-shadow(0px 0px 8px var(--colour-graphite));
      }
    }
  }

  &.hidden {
    opacity: 0.0001;
    margin-top: -100px;
  }

  .rf-logo {
    margin: var(--size-64) 0 var(--size-64) var(--size-32);

    @include touch {
      max-width: 90%;
      margin-left: 5%;
    }
  }
}
