.uppercase,
.upper {
  text-transform: uppercase;
}

.lowercase,
.lower {
  text-transform: lowercase;
}

.titlecase,
.capitalize {
  text-transform: capitalize;
}

@mixin textbox {
  background-color: var(--colour-white);
  padding: var(--size-16) var(--size-32);
}

.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}

.text-right-d {
  @include desktop {
    text-align: right;
  }
}
.text-left-d {
  @include desktop {
    text-align: left;
  }
}
.text-center-d {
  @include desktop {
    text-align: center;
  }
}

.colour-highlight {
  color: var(--colour-highlight) !important;
}
