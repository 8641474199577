footer.page-footer {
  padding-top: var(--size-16);
  padding-bottom: calc(var(--size-32) + var(--size-32));

  a.social-link {
    padding: var(--size-16);
    background-color: var(--colour-white);
    fill: var(--colour-graphite);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in-out;
    margin-right: var(--size-16);

    svg {
      transition: all 0.2s ease-in-out;
    }

    &:hover {
      // box-shadow: 5px 5px 0 rgba(var(--colour-white), 0.5), 10px 10px 0 rgba(var(--colour-white), 0.4), 15px 15px 0 rgba(var(--colour-white), 0.3);
      background-color: var(--colour-graphite);
      svg {
        fill: var(--colour-bg);
      }
    }
  }

  @include touch {
    padding-top: 0;

    a.social-link {
      margin: 0;
      cursor: pointer;
    }
    .page-content {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      max-width: var(--size-512) + var(--size-128);
    }
  }
}
