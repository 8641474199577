.participant {
  .post-header {
    align-items: flex-end;
    position: relative;

    h2 {
      margin-right: 32px;
    }

    img.participant-profile {
      max-width: 300px;
    }

    @include touch {
      flex-direction: column-reverse;
      h2 {
        z-index: 1;
      }

      img.participant-profile {
        transform: translateY(var(--size-24));
        width: 100%;
        max-width: initial;
      }
    }
  }
}

.participant,
.event {
  .post-header {
    h2 {
      margin-right: var(--size-32);
    }

    img.participant-profile {
      max-width: 252px;
    }
  }

  .post-content h2:first-of-type {
    margin-top: 0;
  }
}
