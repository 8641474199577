.prog-list {
  margin: 0;
  padding: 0;
  margin-bottom: var(--size-64);

  li.event {
    .text-lg {
      font-size: var(--size-16);
    }

    @include touch {
      line-height: 1.5;
    }
  }

  .event-time {
    margin: 0 var(--size-16) var(--size-8) 0;
    line-height: 1;
  }

  h2 {
    margin-bottom: var(--size-32);
  }

  .prog-item--format {
    font-size: 11px;
    vertical-align: super;
  }
}

// .news-post {
//   margin-bottom: var(--size-64);
// }

.prog-item {
  list-style: none;
  padding: 0;
  margin-bottom: var(--size-16);

  .prog-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: var(--size-32);
    h2,
    h3,
    label {
      display: inline-flex;
      align-items: center;
      @include textbox;
      margin: 0;
    }

    a {
      color: var(--colour-graphite);
      text-decoration: none;

      &:hover,
      &:visited {
        font-style: normal;
      }
    }
  }

  .prog-content {
    @include textbox;
    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    span,
    a,
    li {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .tag {
    @include textbox;
    display: inline-block;
  }
}
