.error-404 {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  h1,
  p,
  a,
  strong {
    color: var(--colour-graphite);

    @include darkMode {
      background-color: var(--colour-white);
      padding: var(--size-base);
    }
  }

  .fourohfour {
    display: flex;
    flex-direction: row;
  }

  a {
    transition: transform 5s ease-in-out;
  }
}
