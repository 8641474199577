.watch-panel {
  margin-bottom: var(--size-128);
  width: 95vw;
  margin: 0 auto;

  .watch-contents {
    display: flex;

    .left-panel {
      flex: 4;
    }

    .right-panel {
      flex: 1;
      display: flex;
      flex-direction: column;

      &.chat-hidden {
        flex: 0;
        .discord-chat {
          width: 0;

          @include touch {
            display: none;
          }
        }
      }
    }

    #twitch-embed {
      width: 100%;
      height: 100%;
      iframe {
        width: 100%;
        height: 100%;
      }
    }

    .discord-chat {
      iframe {
        width: 100%;
      }
    }

    @include touch {
      flex-direction: column;
      #twitch-embed {
        height: 80vh;
      }
    }
  }

  .watch-controls {
    display: flex;
    justify-content: flex-end;
    margin-top: var(--size-16);

    button {
      cursor: pointer;
      border: none;
      margin-left: var(--size-8);
      background-color: var(--colour-highlight-darker);
      color: var(--colour-white);
    }

    a button {
      background-color: var(--colour-highlight-darker);
    }
  }
}
