body {
  background-color: var(--colour-bg);
  // background-color: var(--colour-graphite);
  background-image: url('../img/bg/bg-2.png');
  transition: background-color 0.5s ease-in-out;
  background-position: var(--size-base) var(--size-8), var(--size-8) var(--size-12);
  background-repeat-x: no-repeat;
  background-repeat-y: repeat;
  background-attachment: fixed;
  display: flex;
  flex-direction: column;
  background-size: 100%;

  @include touch {
    background-attachment: scroll;
    background-size: 200%;
  }

  @include darkMode {
    background-color: var(--colour-graphite);
    // &::after {
    //   content: '';
    //   background-image: ;
    //   opacity: 1;
    //   background-position: 0 0;
    //   background-repeat-x: no-repeat;
    //   background-repeat-y: repeat;
    //   background-size: 100%;
    //   background-attachment: fixed;
    //   height: 100vh;
    //   width: 100vw;
    //   position: fixed;
    //   pointer-events: none;
    // }
  }
}

.page-content {
  max-width: 920px;
  margin: 0 auto;

  &.--wide {
    max-width: 95vw;
  }
}

html,
body {
  height: 100%;
}

.content {
  flex: 1 0 auto;
  max-width: calc(var(--size-512) + var(--size-128));
}

footer.page-footer {
  flex-shrink: 0;
}

.textbox {
  @include textbox;
}

.textbox-sm {
  @include textbox;
  padding: var(--size-8) var(--size-16);
}
