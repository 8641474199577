/* --------------------------------------------------------------------------
 * Breakpoints
 */

// Max mobile
@mixin mobile() {
  @media (max-width: $breakpoint-tablet + 1px) {
    @content;
  }
}

// Min tablet
@mixin tablet() {
  @media (min-width: $breakpoint-tablet) {
    @content;
  }
}

// Tablet only
@mixin tablet-only() {
  @media (min-width: $breakpoint-tablet) and (max-width: $breakpoint-desktop + 1px) {
    @content;
  }
}

// Max tablet
@mixin touch() {
  @media (max-width: $breakpoint-desktop + 1px) {
    @content;
  }
}

// Min Desktop
@mixin desktop() {
  @media (min-width: $breakpoint-desktop) {
    @content;
  }
}

// Desktop only
@mixin desktop-only() {
  @media (min-width: $breakpoint-desktop) and (max-width: $breakpoint-widescreen + 1px) {
    @content;
  }
}

// Min widescreen
@mixin widescreen() {
  @media (min-width: $breakpoint-widescreen) {
    @content;
  }
}

@mixin darkMode() {
  @media (prefers-color-scheme: dark) {
    @content;
  }
}

.hide-mobile {
  @include touch {
    display: none;
  }
}
