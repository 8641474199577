@use "sass:color";

.event {
  .post-header {
    h3,
    a {
      margin: 0;
    }
    h2 {
      display: inline-block;
      align-items: flex-start;

      small {
        display: inline;
        vertical-align: super;
        font-size: var(--size-14);
        white-space: nowrap;
      }
    }
  }

  .post-content {
    h3 {
      font-weight: bold;
    }
  }
  .hosts {
    margin-right: var(--size-8);

    a:last-of-type {
      margin-bottom: 0;
    }
  }
}
