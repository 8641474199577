#page-rf2020 {
  img {
    max-width: 920px;

    @include touch {
      width: 100%;
    }
  }

  p.textbox {
    margin: 0;
    // padding-bottom: 0;
  }

  .textbox {
    li {
      font-size: var(--size-14);

      &:last-of-type {
        margin-bottom: 0;
      }

      &::before {
        content: '-> ';
      }
    }
  }
  .rf2020-nav {
    display: flex;
  }
  .rf2020-nav a {
    display: block;
    margin-right: var(--size-14);

    button {
      -webkit-appearance: none;
      border: none;
      box-shadow: none;
      font-size: var(--size-16);
      cursor: pointer;
    }
  }

  &.lang-fr .no-fr {
    margin-bottom: 1em;
  }
}
