.intro {
  display: block;
  margin-bottom: var(--size-64);
  p {
    @include textbox;
    margin: 0;
  }
}

h2 {
  margin-top: var(--size-32);

  @include touch {
    margin-top: 0;
  }
}

.page-content {
  @include touch {
    @include margin-horizontal(var(--size-32));
  }
}

.side-title {
  position: fixed;
  transition: all 0.2s ease-in-out;
  color: var(--colour-text);
  margin: 0;
  width: 100vh;
  bottom: 0;
  text-align: center;
  z-index: 0;

  &.side-title--left {
    transform: rotate(270deg);
    left: 5%;
    transform-origin: left;

    @include touch {
      left: 15px;
    }
  }

  &.side-title--right {
    transform: rotate(90deg);
    right: 5%;
    transform-origin: right;

    @include touch {
      right: 15px;
    }
  }

  &.hidden {
    opacity: 0.0001;
  }

  a {
    color: var(--colour-text);
  }

  @include darkMode {
    color: var(--colour-white);

    a {
      color: var(--colour-white);
    }
  }
}
