#page-participate {
  h2 {
    display: block;
  }
  &.lang-en h2 {
    margin: 0 203px 16px 0;
  }
  &.lang-fr h2 {
    margin: 0 40px 16px 0;
  }

  @include touch {
    .content h2 {
      margin: 0;
    }
  }

  .content p:last-of-type {
    margin-top: 24px;
  }
}
