#page-event {
  .post-item > div:first-of-type {
    align-items: flex-start;
  }
  .date-box {
    p {
      line-height: 1;
    }
  }
}
