:root {
  // Colours
  --colour-graphite: #1d2022;
  --colour-white: #fff;

  --colour-grey-light: #ececec;
  --colour-turquoise: #0ac2c9;
  --colour-duckegg: #b3bbca;

  --colour-true-blue: #037fe4;
  --colour-red: #f74b22;
  --colour-highlight: var(--colour-turquoise, #0ac2c9);
  --colour-highlight-darker: #06a1a7;

  --colour-bg: #ececec;
  --colour-text: var(--colour-graphite, #1d2022);

  // margin
  --size-base: 4px;
  --size-8: 8px;
  --size-12: 12px;
  --size-14: 14px;
  --size-16: 16px;
  --size-24: 24px;
  --size-32: 32px;
  --size-64: 64px;
  --size-128: 128px;
  --size-256: 256px;
  --size-512: 512px;
  --size-1028: 1028px;

  // Typography
  --font-title: 'SpaceMono', 'Courier New', Courier, monospace;
  --font-logo: 'GlyphWorld Mountain', 'SpaceMono', 'Courier New', Courier, monospace;
  --font-body: 'SpaceMono', 'Courier New', Courier, monospace;
}

// Responsive breakpoints

$breakpoint-tablet: 768px !default;
$breakpoint-desktop: 1024px !default;
$breakpoint-widescreen: 1440px !default;
