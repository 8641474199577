.layout--list-page {
  ul {
    padding: 16px 32px;
    margin-bottom: 64px;
  }

  h1 {
    font-size: var(--size-32);
  }
}
