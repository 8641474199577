#page-gallery {
  a {
    margin: 0;
  }
}

#page-gallery-item {
  .side-title.side-title--left {
    left: 1%;
  }

  h2 {
    @include textbox;
  }

  .gallery-nod {
    width: 95vw !important;
  }
}
