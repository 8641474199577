.clock {
  span {
    color: var(--colour-graphite);
  }

  @include darkMode {
    span {
      color: var(--colour-white);
    }
  }
}
