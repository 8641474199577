#page-watch {
  .stream-toggle,
  .stream-title {
    margin: 0;
    border: none;
    padding: 4px 12px;
    font-weight: bold;
    font-size: 1.3rem;

    &.stream-toggle {
      background-color: var(--colour-white);
      color: var(--colour-graphite);
      cursor: pointer;
    }

    &.stream-a {
      background: var(--colour-red);
      color: var(--colour-white);
    }
    &.stream-b {
      background: var(--colour-highlight);
      color: var(--colour-white);
    }
  }
}
