#page-index {
  h2 {
    margin-bottom: var(--size-32);
  }

  h1.logo {
    margin: var(--size-128) 0;
  }

  h2,
  h3 {
    @include textbox;
    display: inline-block;
    margin-top: 0;
  }

  button {
    -webkit-appearance: none;
    border: none;
    box-shadow: none;
    font-size: var(--size-16);
    cursor: pointer;
  }
}
