.text-page {
  p,
  h1,
  h2,
  h3,
  ul {
    margin: 0;
    @include textbox;

    &:last-of-type:not(ul) {
      margin-bottom: var(--size-32);
    }

    @include touch {
      padding: var(--size-16);
    }
  }

  h2,
  h3 {
    display: inline-block;
    margin-top: var(--size-64);
    margin-bottom: var(--size-32);
  }

  ul {
    font-size: var(--size-14);
    padding-top: 0;

    li {
      list-style: square;
      @include padding-horizontal(var(--size-12));
      line-height: var(--size-24);

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  sup a {
    text-decoration: none;
    // color: var(--colour-turquoise);
  }

  .link-to-submit {
    text-align: center;
  }
}

.buymusic-club {
  max-height: calc(var(--size-512) + var(--size-128));
}
