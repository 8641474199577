@font-face {
  font-family: 'SpaceMono';
  src: url('../fonts/space-mono/SpaceMono-Regular.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'SpaceMono';
  src: url('../fonts/space-mono/SpaceMono-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'SpaceMono';
  src: url('../fonts/space-mono/SpaceMono-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'SpaceMono';
  src: url('../fonts/space-mono/SpaceMono-BoldItalic.ttf') format('truetype');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'GlyphWorld Mountain';
  src: url('../fonts/GlyphWorld/GlyphWorld-Mountain.otf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

// @font-face {
//   font-family: 'JetBrains-Mono';
//   src: url('../fonts/jetbrains-mono/ttf/JetBrainsMono-Bold.ttf') format('truetype');
//   font-weight: 700;
//   font-style: normal;
// }

// @font-face {
//   font-family: 'JetBrains-Mono';
//   src: url('../fonts/jetbrains-mono/ttf/JetBrainsMono-Italic.ttf') format('truetype');
//   font-weight: 400;
//   font-style: italic;
// }

// @font-face {
//   font-family: 'JetBrains-Mono';
//   src: url('../fonts/jetbrains-mono/ttf/JetBrainsMono-Bold-Italic.ttf') format('truetype');
//   font-weight: 700;
//   font-style: italic;
// }

// @font-face {
//   font-family: 'Orator Std Slanted';
//   src: url('../fonts/OratorStd/Orator-Std-Slanted.ttf') format('truetype');
//   font-weight: 400;
//   font-style: italic;
// }
